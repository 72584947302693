/** @format */

import { Entry } from 'contentful';
import { useContext } from 'react';
import { FindColor } from 'src/utils/findThemeColor';
import styled, { ThemeContext } from 'styled-components';
import { breakpointUpTo } from '../../theme/mixins';
import theme from '../../theme/styled-theme';
import { ColumnInfo } from '../../types/page';
import { Image } from '../Image';
import { Container } from './Layout';
import { RichText } from './RichText';

export const SectionColumns = ({ columns }: Props) => {
  const theme = useContext(ThemeContext);

  return (
    <Section as="section" $columns={columns}>
      <CustomBackground>
        {columns.map(({ fields }, index) => (
          <CustomBackgroundColumn
            key={`background-${index}`}
            index={index}
            $image={!!fields.image}
            $background={
              fields.background && FindColor(fields.background.replace(' ', '.').toLowerCase(), theme!.color)
            }
            $titleColor={
              fields.titleColor && FindColor(fields.titleColor.replace(' ', '.').toLowerCase(), theme!.color)
            }
            $textColor={fields.textColor && FindColor(fields.textColor.replace(' ', '.').toLowerCase(), theme!.color)}
            $paragraphStyle={fields.paragraphStyle && fields.paragraphStyle}
          ></CustomBackgroundColumn>
        ))}
      </CustomBackground>
      <CustomContainer $image={!!columns[0].fields.image}>
        {columns.map(({ fields }, index) => {
          return (
            <>
              <WrapperBody
                key={`wrapper-body-${index}`}
                index={index}
                $image={!!fields.image}
                $background={
                  fields.background && FindColor(fields.background.replace(' ', '.').toLowerCase(), theme!.color)
                }
                $titleColor={
                  fields.titleColor && FindColor(fields.titleColor.replace(' ', '.').toLowerCase(), theme!.color)
                }
                $textColor={
                  fields.textColor && FindColor(fields.textColor.replace(' ', '.').toLowerCase(), theme!.color)
                }
                $paragraphStyle={fields.paragraphStyle && fields.paragraphStyle}
              >
                <WrapperText $image={!!fields.image}>
                  <RichText text={fields.text} />
                </WrapperText>
                {fields.image && (
                  <WrapperImageBottom>
                    <Image image={fields.image} />
                  </WrapperImageBottom>
                )}
              </WrapperBody>
              {columns.length !== index + 1 && fields.image && <Hr />}
            </>
          );
        })}
      </CustomContainer>
    </Section>
  );
};

interface StyledProps {
  index: number;
  $image: boolean;
  $background: string;
  $titleColor: string;
  $textColor: string;
  $paragraphStyle: string;
}

interface Props {
  columns: Entry<ColumnInfo>[];
}

const Section = styled.div<{ $columns: Entry<ColumnInfo>[] }>`
  position: relative;
  background: ${({ $columns }) =>
    $columns.length === 2 &&
    `linear-gradient(90deg, ${FindColor(
      $columns[0].fields.background ? $columns[0].fields.background.toLowerCase().replace(' ', '.') : 'white',
      theme.color,
    )} 50%, ${FindColor(
      $columns[1].fields.background ? $columns[1].fields.background.toLowerCase().replace(' ', '.') : 'white',
      theme.color,
    )} 50%)`};
`;

const CustomContainer = styled(Container)<{ $image: boolean }>`
  --padding: ${({ theme }) => theme.container.smallPadding};
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: ${({ $image }) => $image && `6rem 0`};
  flex-shrink: 0;
  padding-block: ${({ $image }) => $image && '6.8rem 6.2rem'};

  ${breakpointUpTo.lg`
      flex-direction:column;
      width:100%;
      padding-block: 0 !important;
  `}

  ${breakpointUpTo.sm`
      align-items: center;
  `}
`;

const WrapperImageBottom = styled.div`
  img {
    object-fit: cover;
    height: auto;
    width: 100%;
  }

  ${breakpointUpTo.lg`
    display:block;
  `}
`;

const WrapperText = styled.div<{ $image: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  height: 100%;

  & > * + * {
    margin-top: 1.5rem;
  }

  p {
    flex-grow: 1;
  }

  ${breakpointUpTo.lg`
    padding-right: 0;
  `}

  ${({ $image }) =>
    $image &&
    breakpointUpTo.sm`
      text-align: center;
      align-items: center;
  `};
`;

const WrapperBody = styled.div<StyledProps>`
  width: 50%;
  gap: 2rem;
  --padding: ${({ theme }) => theme.container.smallPadding};

  display: flex;
  flex-direction: column;
  background-color: ${({ $background }) => $background};

  padding-inline: ${({ $image, $paragraphStyle, index }) =>
    index === 0
      ? $image || $paragraphStyle === 'Big'
        ? '1rem 5rem'
        : '3rem 5rem'
      : $image
      ? '5rem 0'
      : $paragraphStyle === 'Big'
      ? '3.75rem 0'
      : '6rem 0'};

  padding-block: ${({ $image, $paragraphStyle }) => {
    if ($paragraphStyle === 'Big' && !$image) return '8.5rem 7.5rem';
    if ($image) return '0';
    return '6.8rem 6.2rem';
  }};
  ${breakpointUpTo.lg`
      padding-inline: var(--padding);
      padding-block: 3.75rem;
  `} h3 {
    color: ${({ theme, index, $titleColor }) =>
      index === 0
        ? $titleColor
          ? $titleColor
          : theme.color.teal.light
        : $titleColor
        ? $titleColor
        : theme.color.pink};

    em {
      color: ${({ theme, $titleColor }) => ($titleColor ? $titleColor : theme.color.pink)};
    }
  }
  h4 {
    color: ${({ theme, index, $textColor }) =>
      index === 0
        ? $textColor
          ? $textColor
          : theme.color.teal.dark
        : $textColor
        ? $textColor
        : theme.color.teal.dark};
    text-wrap: balance;
    em {
      color: ${({ theme }) => theme.color.teal.dark};
    }
  }
  p {
    max-width: ${({ $paragraphStyle }) => ($paragraphStyle === 'Big' ? '37rem' : '35rem')};

    ${({ $paragraphStyle, theme }) =>
      $paragraphStyle === 'Big'
        ? `font-family: ${theme.font.title};
    font-size: clamp(${theme.font.size[1.4375]}, 1.3333rem + 0.4630vw, ${theme.font.size[1.75]});
    font-style: normal;
    font-weight: 300;
    line-height: clamp(2.3125rem, 2.0896rem + 0.9907vw, 2.98125rem);
    letter-spacing: clamp(0.02875rem, 0.0267rem + 0.0093vw, 0.035rem);`
        : `font-family: ${theme.font.body};
    font-size: clamp(${theme.font.size[1]}, 0.9583rem + 0.1852vw, ${theme.font.size[1.125]});
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1.375rem, 1.3333rem + 0.1852vw, 1.5rem);
    letter-spacing: clamp(0.03rem, 0.0287rem + 0.0056vw, 0.03375rem);`};

    color: ${({ theme, $textColor }) => ($textColor ? $textColor : theme.color.teal.dark)};
  }
  ${breakpointUpTo.lg`
    flex-direction: ${({ $image }: StyledProps) => $image && 'column-reverse'};
    width:100%;
    `}
`;

const Hr = styled.hr`
  border: 1px solid ${({ theme }) => theme.color.teal.dark};
  ${breakpointUpTo.sm`
    width:85%;
  `}
`;

const CustomBackground = styled.div`
  z-index: 0;
  top: 0;
  left: 50%;
  position: absolute;
  max-width: 1440px;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;

  ${breakpointUpTo.lg`
    display:none;
    flex-direction:column;
  `}
`;

const CustomBackgroundColumn = styled.div<StyledProps>`
  width: 50%;
  gap: 2rem;

  display: flex;
  flex-direction: column;
  z-index: 0;
  background-color: ${({ $background }) => $background};

  ${breakpointUpTo.lg`
    width: 100%;
  
  `}

  padding-inline: ${({ $image, $paragraphStyle, index }) =>
    index === 0
      ? $image || $paragraphStyle === 'Big'
        ? '0 5rem'
        : '3rem 5rem'
      : $image
      ? '5rem 0'
      : $paragraphStyle === 'Big'
      ? '3.75rem 0'
      : '6rem 0'};

  padding-block: ${({ $image, $paragraphStyle }) => {
    if ($paragraphStyle === 'Big' && !$image) return '8.5rem 7.5rem';
    if ($image) return '0';
    return '6.8rem 6.2rem';
  }};

  ${breakpointUpTo.lg`
      padding-inline: 0;
      padding-block: 3.75rem;
  `}
`;
